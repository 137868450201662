import React from "react";
import Countdown, { zeroPad } from "react-countdown";
import logo from "../iraniaalanyalogo.svg";
import "../css/UnderConstruction.css";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function UnderConstruction() {
  const analytics = getAnalytics();
  logEvent(analytics, "select_content", {
    content_type: "image",
    content_id: "P12453",
    items: [{ name: "Kittens" }],
  });
  const Completionist = () => <span>You are good to go!</span>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <>
          <div class='countdown-container'>
            <div class='countdown-caption'>
              <div>Days</div>
              <div>Minutes</div>
              <div>Seconds</div>
            </div>
            <div class='countdown-body'>
              <div>
                <div>{zeroPad(hours).toString().slice(0, 1)}</div>
                <div>{zeroPad(hours).toString().slice(1, 2)}</div>
              </div>
              <div>
                <div>{zeroPad(minutes).toString().slice(0, 1)}</div>
                <div>{zeroPad(minutes).toString().slice(1, 2)}</div>
              </div>
              <div>
                <div>{zeroPad(seconds).toString().slice(0, 1)}</div>
                <div>{zeroPad(seconds).toString().slice(1, 2)}</div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <div className='animated-bg'>
        <div></div>
      </div>
      <div className='container-fluid bg-dark bg-opacity-75'>
        <div className='row vh-100 justify-content-center align-content-center'>
          <div className='col-9 col-sm-7 col-md-5 col-lg-4 col-xl-3 col-xxl-2'>
            <img src={logo} alt='' />
          </div>
          <div className='w-100'></div>
          <div className='col-10 col-xl-6'>
            <Countdown date={Date.now() + 20000000} renderer={renderer} />
          </div>
        </div>
      </div>
    </>
  );
}
