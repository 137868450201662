// import logo from "./logo.svg";
import "./css/App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
// import UnderConstruction from "./pages/UnderConstruction";
import { useState } from "react";
import i18n from "./i18n";
import LanguageContext from "./Contexts/LanguageContext";
import { useTranslation } from "react-i18next";
import UnderConstruction from "./pages/UnderConstruction";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
function App() {
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation();
  const firebaseConfig = {
    apiKey: "AIzaSyB7GqcgzBNHXNtgX0lsxCEjjIjilnwE-tQ",
    authDomain: "iraniaalania.firebaseapp.com",
    projectId: "iraniaalania",
    storageBucket: "iraniaalania.appspot.com",
    messagingSenderId: "299703227640",
    appId: "1:299703227640:web:efcea31a8ea01412b6170d",
    measurementId: "G-EYY62JXYJT",
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Analytics and get a reference to the service
  const analytics = getAnalytics(app);
  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<UnderConstruction />}>
            <Route index={true} element={<Home />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </LanguageContext.Provider>
  );
}

export default App;
